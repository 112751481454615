/** @jsx jsx */
import { useContext } from 'react'
import { jsx, Styled, useThemeUI } from 'theme-ui'
import { ContentfulModule } from '@h/modules'
import { Col, Row } from '@uswitch/trustyle.flex-grid'
import ModuleWrapper, { ModuleWrapperContext } from '@h/module-wrapper'

export default ({
  title,
  entries = [],
  children,
  colsMobile = 1,
  colsTablet = 2,
  colsDesktop = 3,
  divided = false,
  options = {},
}) => {
  // Deprecated - use spacing override modules instead
  const wrapperContext = useContext(ModuleWrapperContext)
  const defaultSpacing = wrapperContext.alreadyWrapped ? 0 : 'lg'
  const { spacingTop = defaultSpacing, spacingBottom = defaultSpacing } = options

  const rowStyles = options?.styles?.row || {}
  const colStyles = options?.styles?.col || {}

  const { theme } = useThemeUI()

  const offsetDesktop = theme.space.lg
  const offsetMobile = theme.space.md
  const rowSx = divided
    ? { mx: [-offsetMobile, -offsetMobile, -offsetDesktop], ...rowStyles }
    : { ...rowStyles }
  const colSx = {
    variant: divided ? 'modules.collectionGrid.colDivided' : 'modules.collectionGrid.col',
    '> div': { height: '100%' },
    ...colStyles,
  }

  const childrenArray = entries
    ? entries.map((entity, i) => <ContentfulModule props={{ entity }} key={i} />)
    : children

  return (
    <ModuleWrapper
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      sx={{ variant: 'modules.collectionGrid.wrapper' }}
    >
      <If condition={title}>
        <Styled.h2>{title}</Styled.h2>
      </If>
      <Row cols={[colsMobile, colsTablet, colsDesktop]} direction='row' wrap sx={rowSx}>
        <For index='key' each='child' of={childrenArray}>
          <Col span={1} key={key} sx={colSx}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <child.type {...child.props} />
          </Col>
        </For>
      </Row>
    </ModuleWrapper>
  )
}
